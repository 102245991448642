<!--文字生图吧-->

<template>
  <section class="file-upload">
    <div class="fileupload-container">

      <div class="c1">
        <div style="width: 100%;">
          <div style="float: right;margin: 6px 12px 10px 6px;">
            <div style="display:inline-block">
            </div>
          </div>
        </div>
      </div>

      <div class="c2">
        <div style="margin-bottom:4px">
          <h1 class="title">文字生图吧</h1>
          <transition name="fade">
            <img v-bind:src=bannerImage
                 class="effect-img" alt="文字生图吧 - AI生成图片，轻松创作海报、插图、封面"
                 style="border: 1px solid #f1f1f1;">
          </transition>
        </div>

        <h3 class="tips">
          <transition name="fade">
            <span>{{ tip }}</span>
          </transition>
          <a v-if="!isQuantityMembership()" class="link-button" v-on:click="routeToPointPackage"
             style="margin-left:9px;">加入会员</a>
        </h3>

        <div class="advanced-container">
          <transition name="fade">
            <div class="fade-container">
              <!--选择尺寸-->
              <div class="target-resolution">
                <span class="item" @click="onClickItem('CUSTOM')">
                  自定义
                  <span class="custom-size-container">
                    <b-form-input type="number" class="custom-width" v-model="outputImageWidth" placeholder="宽"
                                  size="sm" @blur="onCustomWidthBlur" min="10" max="1600"
                                  autocomplete="off"></b-form-input>
                    <b-form-input type="number" class="custom-height" v-model="outputImageHeight" placeholder="高"
                                  size="sm" @blur="onCustomHeightBlur" min="10" max="1600"
                                  autocomplete="off"></b-form-input>
                  </span>
                </span>
                <span class="item" @click="onClickItem('RATIO_1_1')">1:1</span>
                <span class="item" @click="onClickItem('RATIO_2_1')">2:1</span>
                <span class="item" @click="onClickItem('RATIO_2_3')">2:3</span>
                <span class="item" @click="onClickItem('RATIO_3_4')">3:4</span>
                <span class="item" @click="onClickItem('RATIO_9_16')">9:16</span>
                <span class="item" @click="onClickItem('RATIO_1_2')">1:2</span>
                <span class="item" @click="onClickItem('RATIO_3_2')">3:2</span>
                <span class="item active" @click="onClickItem('RATIO_4_3')">4:3</span>
                <span class="item" @click="onClickItem('RATIO_16_9')">16:9</span>
                <span class="item" @click="onClickItem('RATIO_1920x1080')">1920x1080</span>
                <span class="item" @click="onClickItem('RATIO_1080x1920')">1080x1920</span>
              </div>
              <!--选择模型-->
              <div class="upload-options">
                <b-form-checkbox-group size="sm" style="display:inline-block;font-size:13px;"
                                       v-model="uploadOptionSelected"
                                       :options="uploadOptions"
                                       @change="uploadOptionChange"
                                       name="upload-options-checkbox">
                </b-form-checkbox-group>
              </div>
              <!--张数与风格-->
              <div class="control-options">
                <!--生成张数-->
                <div class="generated-image-number-container">
                  <div class="generated-image-number-wrapper">
                    <span class="label">生成张数：</span>
                    <span class="value">{{ imageNumber }}</span>
                    <b-form-input class="input" v-model="imageNumber" type="range" min="1"
                                  max="8"></b-form-input>
                  </div>
                </div>
                <!--选择风格-->
                <div class="style-container">
                  <div class="style-wrapper">
                    <span class="label">风格：</span>
                    <div class="style-preview">
                      <div class="image-wrapper" v-for="(item,i) of variationStyles" :key="i"
                           v-b-hover="previewImageHoverHandler"
                           v-b-tooltip.hover v-bind:title="item.name">
                        <img v-bind:src="item.images[0]"/>
                        <div class="img-remove" v-on:click="removeSelectedStyle(item)">
                          <div class="icon-delete-bg hidden"></div>
                          <span class="icon-trash-2 icon hidden"></span>
                        </div>
                      </div>
                      <div v-if="this.variationStyles.length<3" class="image-wrapper add-style">
                        <img v-on:click="$root.$refs.StyleSelectionModal.openModal()"
                             src="/images/add.svg" alt="添加风格"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--提示词-->
              <div class="prompt-section">
                <div class="prompt-wrapper">
                  <b-form-textarea class="prompt-input" v-model="prompt"
                                   rows="2" size="sm"
                                   placeholder="提示词如：美丽的森林，有山有水，草地绿油油，小溪清澈见底"
                                   v-on:keydown="promptKeyDown()"
                                   v-on:keyup="promptKeyUp()">
                  </b-form-textarea>
                  <div style="text-align:right;padding:0 8px;font-size:13px;">
                    <span style="margin-right:12px;color:#aba9a9">{{ promptCount }} / {{ promptCountLimit }}</span>
                    <span class="icon-trash-2 icon" v-on:click="clearPrompt"></span>
                  </div>
                </div>
              </div>
              <!--立即运行按钮-->
              <div>
                <b-button class="run-btn" variant="primary" pill
                          :disabled="startButtonDisabled" @click="onStartTask">立即生图
                </b-button>
              </div>
            </div>
          </transition>
        </div>

        <div class="pond-container">

          <div class="response-message">
            <div>
              <!-- GENERIC_INFO 显示-->
              <div v-if="messageType==='GENERIC_INFO'" style="color:#198f35">
                <div>
                  <b-icon icon="three-dots" animation="cylon" font-scale="3"></b-icon>
                </div>
                <div><span> {{ message }}</span></div>
              </div>
              <!-- GENERIC_ERROR 显示-->
              <div v-else-if="messageType==='GENERIC_ERROR'" style="color:#db2828;">
                <div style="margin-top:15px;">
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ message }}
                </div>
              </div>
              <!-- LOCAL_SERVICE_UNAVAILABLE 显示（LOCAL_ 开头的消息是前端仅有的）-->
              <div v-else-if="messageType==='LOCAL_SERVICE_UNAVAILABLE'" style="color:#db2828;">
                <div style="margin-top:15px;">
                  <span v-html="message"></span>
                </div>
              </div>
              <!-- LOCAL_DOWNLOAD_FAILED 显示（LOCAL_ 开头的消息是前端仅有的）-->
              <div v-else-if="messageType==='LOCAL_DOWNLOAD_FAILED'">
                <div style="text-align:center;color:#4f4f4f;margin-top:15px;">
                  <span v-if="!alternateDownloadAddress">
                      <span v-if="isLoadingAlternateDownloadAddress">
                        <b-icon icon="arrow-clockwise" animation="spin" font-scale="1.27"
                                style="position:relative;top:2px;"></b-icon>
                        获取备用地址......
                      </span>
                      <span v-else style="cursor:pointer;" v-on:click="loadAlternateDownloadAddress()">下载失败？</span>
                  </span>
                  <span v-if="alternateDownloadAddress" class="download-link"
                        v-on:click="doAlternateDownloadFile()">点击备用下载
                      <b-icon icon="download" font-scale="1" style="margin:0 3px;"></b-icon>
                  </span>
                </div>
              </div>

              <!-- GENERIC_BATCH_PROCESS_WAITING 显示-->
              <div v-else-if="messageType==='GENERIC_BATCH_PROCESS_WAITING'"
                   style="color:#198f35">
                <div>
                  <b-icon icon="three-dots" animation="cylon" font-scale="3"></b-icon>
                </div>
                <div v-html="message"></div>
              </div>
              <!-- GENERIC_BATCH_PROCESS_PROGRESS 显示-->
              <div v-else-if="messageType==='GENERIC_BATCH_PROCESS_PROGRESS'"
                   style="color:#198f35">
                <div>
                  <b-icon icon="three-dots" animation="cylon" font-scale="3"></b-icon>
                </div>
                <div v-html="message"></div>
              </div>
              <!-- GENERIC_BATCH_PROCESS_RESULT 显示-->
              <div v-else-if="messageType==='GENERIC_BATCH_PROCESS_RESULT'" style="color:#155724">
                <div style="margin-top:15px;color:#178d88;">
                  图片 <span style="color:#db2828;font-size:12px;">{{ batchResult.finishedTime }}</span>
                  <span v-if="batchResult.successCount>0"> 就绪</span>
                  <span v-if="batchResult.successCount===0"> 结束</span>
                  ，成功 <span style="font-size:13px;">{{ batchResult.successCount }}</span>
                  失败
                  <span style="font-size:13px;"
                        v-bind:class="{ 'error-message-underline':batchResult.errorCount>0 }"
                        v-on:click="makeToast('danger')">{{ batchResult.errorCount }}
                  </span>
                  <span v-if="batchResult.nsfwCount>0" style="margin-left:2px;">
                  不雅 <span style="font-size:13px;">{{ batchResult.nsfwCount }}</span>
                  </span>

                  <span v-if="batchResult.compressionInfo">，压缩 <span
                      style="font-size:13px;">{{ batchResult.compressionInfo }}</span></span>

                  <span class="download-link"
                        v-if="batchResult.successCount!==0&&(batchResult.url||batchResult.OssUrl)"
                        v-on:click="doDownloadFile()">，点击下载&nbsp;
                                            <b-icon icon="download" font-scale="1.2"
                                                    style="margin-left:2px;"></b-icon>
                                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--预览图-->
        <div v-if="messageType==='GENERIC_BATCH_PROCESS_RESULT'">
          <div class="thumbnail-preview-list">
            <div class="thumbnail-container" v-for="(thumbnailUrl,i) of batchResult.thumbnailUrls" :key="i">
              <img class="thumbnail" v-bind:src="thumbnailUrl" alt="" height="130">
            </div>
          </div>
        </div>

      </div>

      <div class="c3">
        <div class="ads"></div>
      </div>
      <div class="c4"></div>
      <div class="c5"></div>
    </div>
    <div :style="{height:screenHeight}"></div>
    <style-selection-modal/>
  </section>
</template>

<script>
import $ from 'jquery';
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap/dist/js/bootstrap.min.js'

import ImageFile from "@/utils/imagefile";
import common from "@/utils/common";
import UploadCommon from "@/utils/upload.common";
import StyleSelectionModal from "@/pages/imagevariation/components/StyleSelectionModal.vue";

// 设置产品变量
let productCategory = 'TEXT_TO_IMAGE'  //产品类型

// Vue 本组件内有效
let self = null;

export default {
  name: productCategory,
  data() {
    return {
      screenHeight: (screen.height - 500) + 'px',
      bannerImage: '',
      similarImage: '',
      startButtonDisabled: true,

      message: '',
      messageType: '',

      batchResult: null,
      alternateDownloadAddress: '',
      isLoadingAlternateDownloadAddress: false,

      tip: [],

      imageNumber: 1,
      uploadOptionSelected: [], // 必须是数组
      uploadOptions: [
        {'text': '写实', 'value': 'REALISTIC', 'disabled': false},
        {'text': '卡通', 'value': 'ANIME', 'disabled': false},
        {'text': '艺术', 'value': 'ARTIST', 'disabled': false}
      ],

      isCustomSize: false,
      outputImageWidth: null,
      outputImageHeight: null,
      imageRatio: 'RATIO_4_3',

      variationStyles: [],
      allVariationStyles: [],

      prompt: null,
      promptCount: 0,
      promptCountLimit: 800
    }
  },
  computed: {},
  created() {  // 模板渲染成html前调用
    // 设置最近访问的产品 URL
    this.LocalStorage.setLastAccessRoute('/texttoimage');
    //设置生成数量
    this.imageNumber = this.isQuantityMembership() ? 2 : 1
    // 设置封面
    UploadCommon.setBannerImage(this, 'product-cover/texttoimage-free.png', 'product-cover/texttoimage-vip.png')
    //设置默认选择的样式
    this.loadDefaultSelectionStyles()
  },
  mounted() {  // 模板渲染成html后调用
    self = this;

    UploadCommon.startConnecting(self, productCategory);
    UploadCommon.monitorUserLoginChangeRealTime();
    self.showMemberTips();

    // 重置数据
    this.message = '';
    this.messageType = '';
    this.alternateDownloadAddress = '';
    this.startButtonDisabled = false

    // 设置 Footer 是否可见
    common.setSeoFooterElementVisible(true)
  },
  updated() {
  },
  components: {StyleSelectionModal},
  methods: {
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },
    isQuantityMembership: function () {
      const loginUser = this.getLoginUser();
      return loginUser ? loginUser.isQuantityMembership : false;
    },
    getQuantityMembershipPoints: function () {
      const loginUser = this.getLoginUser();
      return loginUser ? loginUser.quantityMembershipPoints : 0;
    },
    onClickItem(value) {
      let $target = $(event.target)
      $target.closest('.target-resolution').find('.item').removeClass('active')
      let $item = $target.hasClass('item') ? $target : $target.closest('.item')
      $item.addClass('active')

      this.imageRatio = value

      if (value !== 'CUSTOM') {
        this.isCustomSize = false;
      } else {
        this.isCustomSize = true;
      }
    },
    onCustomWidthBlur(event) {
      let value = event.target.value
      if (!value) return
      if (!this.isNumberIn1To1600(value)) {
        this.outputImageWidth = 1600
      }
    },
    onCustomHeightBlur(event) {
      let value = event.target.value
      if (!value) return
      if (!this.isNumberIn1To1600(value)) {
        this.outputImageHeight = 1600
      }
    },
    isNumberIn1To1600(value) {
      const num = Number(value);
      return num >= 1 && num <= 1600 && Number.isInteger(num);
    },
    resetUpload: function () {
      //文生图是无上传图片的，但为了支持本地下载后显示 "下载失败？" ，这个方法保留，用于设置 messageType=""，以便显示 "下载失败？"
      this.message = '';
      this.messageType = '';
    },
    doDownloadFile() {
      UploadCommon.doDownloadFile()
    },
    loadAlternateDownloadAddress() {
      UploadCommon.loadAlternateDownloadAddress()
    },
    doAlternateDownloadFile() {
      UploadCommon.doAlternateDownloadFile()
    },
    makeToast(variant = null) {
      UploadCommon.makeToast(variant)
    },
    showMemberTips: function () {
      const self = this;
      const tips = [
        '轻松文生图，支持手机端随手创作'
      ];

      let myindex = self.Common.randomIntRange(0, 1);
      self.tip = tips[myindex];
      myindex += 1;

      setInterval(function () {
        if (myindex >= tips.length) myindex = 0;
        self.tip = tips[myindex++];
      }, 15000)
    },
    showMessage: function (type, message, isShowResetBtn) {
      this.messageType = type;
      this.message = message;

      if (type === 'GENERIC_ERROR' || type === 'GENERIC_BATCH_UPLOAD_ERROR') {
        //this.isShowResetButton = true;
      }
    },
    showErrorMessage: function (message, isShowResetBtn) {
      this.showMessage('GENERIC_ERROR', message, isShowResetBtn);
    },
    routeToPointPackage: function () {
      this.$router.push({path: '/package'});

      // 滚动
      setTimeout(function () {
        let targetElement = document.getElementById('quantity-pack-title');
        targetElement.scrollIntoView(true);
      }, 100)
    },
    uploadOptionChange: function (selectedValues) {
      this.uploadOptionSelected = selectedValues;

      // 最后一个勾选的始终在 selectedValues 数组的最后，借助此特性来处理交互上的限制
      const lastValue = selectedValues[selectedValues.length - 1];

      // 规则：边缘优化、人像优化只能二选一
      if (lastValue === 'REALISTIC') {
        this.variationStyles = this.getVariationStyleByCode(['V2', 'Photograph'])
      } else if (lastValue === 'ANIME') {
        this.variationStyles = this.getVariationStyleByCode(['V2', 'Semi Realistic'])
      } else if (lastValue === 'ARTIST') {
        this.variationStyles = this.getVariationStyleByCode(['V2'])
      } else {
        this.variationStyles = this.getVariationStyleByCode(['V2', 'Enhance'])
      }

      this.uploadOptionSelected = lastValue ? [lastValue] : [];
    },
    addStyle(item) {
      // 1.检查是否已存在
      for (let i = 0; i < this.variationStyles.length; i++) {
        if (this.variationStyles[i].code === item.code) {
          return
        }
      }
      // 2.不存在则添加
      this.variationStyles.push(item)
    },
    removeSelectedStyle(item) {

      // 获取删除位置
      let deleteIndex = -1
      for (let i = 0; i < this.variationStyles.length; i++) {
        if (this.variationStyles[i].code === item.code) {
          deleteIndex = i;
          break
        }
      }

      // 删除
      this.variationStyles.splice(deleteIndex, 1)

      // 释放内存
      let windowURL = window.URL || window.webkitURL;
      windowURL.revokeObjectURL(item.images[0]);
    },
    previewImageHoverHandler(isHovered) {
      let imgRemoveElement = $(event.target).find('.img-remove')
      if (isHovered) {
        imgRemoveElement.find('.icon-delete-bg,.icon-trash-2').removeClass('hidden')
      } else {
        imgRemoveElement.find('.icon-delete-bg,.icon-trash-2').addClass('hidden')
      }
    },
    loadDefaultSelectionStyles() {
      if (this.variationStyles && this.variationStyles.length > 0) return;

      const url = this.Server.getCloudServiceAddress() + '/api/imageVariations/style/allDefaultSelections';
      const resp = this.Ajax.get(url, false);
      if (resp && resp.success) {
        this.allVariationStyles = resp.data
        this.variationStyles = this.getVariationStyleByCode(['V2', 'Enhance'])
      }
    },
    getVariationStyleByCode(codes) {
      let result = []
      for (let variationStyle of this.allVariationStyles) {
        if (codes.indexOf(variationStyle.code) !== -1) {
          result.push(variationStyle)
        }
      }
      return result
    },
    clearPrompt() {
      this.prompt = ''
    },
    promptKeyDown() {
    },
    promptKeyUp() {
      let val = $(event.target).val()
      this.promptCount = val.length
      if (this.promptCount > this.promptCountLimit) {
        this.prompt = val.substring(0, this.promptCountLimit)
        this.promptCount = this.promptCountLimit
      }
    },
    onStartTask() {
      if (this.isCustomSize) {
        // 1.自定义尺寸必须设置宽、高
        if (!this.outputImageWidth || !this.outputImageHeight) {
          this.showErrorMessage(`请自定义宽、高`);
          return;
        }

        // 2.检查宽高是否可以被8整除，不能则给出提示后直接处理，以减少用户使用的步骤。
        let outputImageWidthInt = parseInt(this.outputImageWidth)
        let outputImageHeightInt = parseInt(this.outputImageHeight)
        let offsetW = outputImageWidthInt % 8
        let offsetH = outputImageHeightInt % 8
        if (offsetW !== 0 && offsetH !== 0) {
          this.outputImageWidth = outputImageWidthInt + (8 - offsetW)
          this.outputImageHeight = outputImageHeightInt + (8 - offsetH)
          this.Common.toastInfo(this.$bvToast, '提示',
              `自定义 宽高 调到 ${this.outputImageWidth},${this.outputImageHeight} 以满足被 8 整除`);
        } else if (offsetW !== 0) {
          this.outputImageWidth = outputImageWidthInt + (8 - offsetW)
          this.Common.toastInfo(this.$bvToast, '提示',
              `自定义 宽 调到 ${this.outputImageWidth} 以满足被 8 整除`);
        } else if (offsetH !== 0) {
          this.outputImageHeight = outputImageHeightInt + (8 - offsetH)
          this.Common.toastInfo(this.$bvToast, '提示',
              `自定义 高 调到 ${this.outputImageHeight} 以满足被 8 整除`);
        }
      }

      // 3.启动任务
      UploadCommon.startTask()
      // 4.前端显示下进度动画，否则点了卡2秒体验不好
      this.showMessage('GENERIC_INFO', '');
    }
  }
}

</script>

<style lang="scss" scoped>

.file-upload {
  color: #333639;
  /*background-color: #1e2430;*/
  min-height: 27rem;
  overflow-x: hidden;
}

.pond-container, .advanced-container {
  width: 28rem;
}

.pond {
  width: 28rem;
  height: 4rem;
  margin: 0 auto;
  /*opacity: 0;*/
  transition: opacity 1s .25s;
}

.pond-multiple {
  width: 100%;
  height: 4rem;
  margin: 0 auto;
  /* opacity: 0; */
  transition: opacity 1s 0.25s;
}

.upload-options {
  display: inline-block;
  //margin-top: 18px;
  font-size: 0.875rem;
  margin-top: 4px;
  margin-bottom: 6px;
  font-weight: 300;
  /*由于最后一个checkbox lable 是左对齐的，导致右边会有点空，所以将整个group margin-left 点*/
  margin-left: 10px;
  color: #4f4f4f;
}

.response-message {
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}

.error-icon {
  margin-right: 5px;
}

.filepond--drop-label {
  font-weight: 400;
  text-align: center;
  overflow: hidden;
}

.filepond--drop-label > label {
  /*color: #c7ccd8;*/
}

.filepond-browse {
  text-decoration: underline;
  text-decoration-color: #babdc0;
  cursor: pointer;
}

.fileupload-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.download-link {
  cursor: pointer;
}

.download-link:hover {
  cursor: pointer;
}

.reset-upload {
  color: #727a79;
  text-decoration-line: underline;
  cursor: pointer;
}

.reset-upload:hover {
  color: #009991;
}

.title {
  color: #444;
  font-size: 30px;
}

.desc {
  font-size: 14px;
  color: #8f8f8f;
  display: none;
}

.tips {
  font-size: 13px;
  color: #8f8f8f;
  margin: 5px 0 10px 0;
  line-height: 18px;
}

.link-button {
  margin-left: 10px;
  color: #c18c00;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
}

.link-button:hover {
  color: #ce9c19;
  text-decoration: underline;
}

.effect-img {
  width: 26rem;
  height: 177px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  /*width: 350px;*/
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
  .effect-img {
    width: 95%;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 1rem;
  }
}

.c1 {
  /*display: flex;*/
  display: none;
  align-items: center;
  padding: 6px;
}

.c2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  //手机端内容靠上点比较合适，大拇指正好落在上传控件处
  margin-top: 33%;
}

.c3 {
  display: block;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c4 {
  flex-grow: 1;
}

.c5 {
  background: #f2f2f2;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.email-icon {
  height: 45px;
}

.ads {
  margin-top: 70px;
  font-size: 13px;
  text-align: center;
}

.ad-link {
  cursor: pointer;
  color: #8f8f8f;
}


a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.error-message {
  color: #db2828;
}

.error-message-underline {
  color: #db2828;
  text-decoration: underline;
  cursor: pointer;
}

.warning-message {
  /*color: #856404;*/
}

.error-info {
  width: 90%;
  height: 4rem;
  margin: 0 auto;

  position: absolute;
  top: 60px;
  font-size: 14px;
  line-height: 26px;

  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);

  padding: 15px 8px;
}

.b-tooltip .arrow {
  display: none;
}

.b-tooltip .tooltip-inner {
  color: #fff;
  background-color: #8d7353;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  border-right-color: #dc3545;
}

.b-toast-danger.b-toast-solid .toast {
  background-color: #fff;
}

.b-toast-danger .toast .toast-header {
  color: #721c24;
  /*background-color: rgba(248, 215, 218, 0.85);*/
  background-color: #fcedee;
  border-bottom-color: rgba(245, 198, 203, 0.85);
}

.b-toast-danger .toast {
  /*background-color: rgba(252, 237, 238, 0.85);*/
  background-color: #fff;
  border-color: rgba(245, 198, 203, 0.85);
  color: #721c24;
}

.urgent-notice {
  /*color: #db2828;*/
  color: green;
  font-size: 12px;
  padding-top: 30px;
}

.advanced-container {
  padding-bottom: 2px;

  .generated-image-number-container {
    display: inline-block;
    margin-bottom: 10px;

    .generated-image-number-wrapper {
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      margin-bottom: 2px;
      color: #555;
      font-size: 14px;

      .value {
        margin: 0 10px 0 0;
      }

      .input {
        width: 38px;
      }
    }
  }

  .style-container {
    display: inline-block;
    margin-left: 30px;

    .style-wrapper {
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      margin-bottom: 2px;
      color: #555;
      font-size: 14px;

      .label {
        white-space: nowrap;
      }

      .style-preview {
        display: inline-flex;
        align-items: center;
        width: 100%;
        overflow: hidden;

        .image-wrapper {
          display: flex;
          position: relative;

          img {
            width: 22px;
            height: 24px;
            margin-right: 4px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            border: 1px solid #e1e1e1;
          }

          .img-remove {
            display: inline-block;
            cursor: pointer;

            .icon-trash-2 {
              position: absolute;
              right: 10px;
              top: 6px;
              font-size: 11px;
              color: #fff;
            }

            .icon-delete-bg {
              background-color: #4E4E4E49;
              position: absolute;
              height: 20px;
              width: 20px;
              right: 5px;
              top: 1px;
              border-radius: 3px;
              -webkit-border-radius: 3px;
            }
          }
        }

        .add-style {
          cursor: pointer;

          img {
            width: 27px;
            height: 27px;
            border: none;
          }
        }
      }
    }
  }
}

.fade-container {
  display: flex;
  flex-direction: column;
}

.target-resolution {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;

  .item {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    height: 23px;
    background-color: #fafafa;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    color: #0f0f0f;
    line-height: 20px;
    margin: 3px;
    min-width: 12px;
    cursor: pointer;
    border-radius: 8px;
    -webkit-border-radius: 8px;
  }

  .item:hover {
    //color: #2d89f5;
    //background-color: #f6faff;
  }

  .item.active {
    color: #f6faff;
    background-color: #2d89f5;
  }

  .custom-size-container {
    display: flex;

    .custom-width {
      width: 60px !important;
      height: 19px;
      margin-left: 8px;
      margin-right: 8px;
    }

    .custom-height {
      width: 60px !important;
      height: 19px;
    }
  }

  .form-control {
    border: none;
  }

  .form-control:focus {
    //border-color: transparent;
    box-shadow: none;
  }

  .form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem 0 .25rem .4rem;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 2px;
  }
}

.prompt-section {
  border: 1px solid #999ca2;
  border-radius: 8px;
  padding: 2px;
  position: relative;
  margin-bottom: 20px;

  .prompt-input {
    width: 27rem;
  }

  .prompt-wrapper {

    textarea {
      border: none;
      box-shadow: none !important;
      resize: none;

      //box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    }

    .icon-trash-2 {
      cursor: pointer;
    }

    /* 整个滚动条 */
    textarea::-webkit-scrollbar {
      width: 10px;
    }

    /* 滚动条的滚动区域（轨道） */
    textarea::-webkit-scrollbar-track {
      border-radius: 1px;
    }

    /* 滚动条的可拖拽部分（滑块） */
    textarea::-webkit-scrollbar-thumb {
      background: #bbbbbb;
      border-radius: 10px;
      visibility: visible; /* 影藏滚动条样式 */
      border: solid 2px #fff; /* 将 width 边框设白色看起来像有 padding 效果，改滚动条宽度就改这里即可 */
    }
  }
}

.run-btn {
  width: 180px;
}

.thumbnail-preview-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 25px;

  //border: 1px solid #e1e1e1;
  //border-radius: 12px;
  //-webkit-border-radius: 12px;

  .thumbnail-container {
    display: inline-block;
    margin: 10px 10px;
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    -webkit-border-radius: 12px;

    .thumbnail {
      border-radius: 12px;
      -webkit-border-radius: 12px;
    }
  }
}

//input[type="number"] {
//  -moz-appearance: textfield; /* Firefox */
//  appearance: textfield;
//}
//
//input[type="number"]::-webkit-inner-spin-button,
//input[type="number"]::-webkit-outer-spin-button {
//  -webkit-appearance: none; /* Safari */
//  margin: 0;
//}

@media only screen and (min-width: 601px) {
  .c2 {
    margin-top: 135px;
  }
}

@media only screen and (max-width: 601px) {
  .pond-container, .advanced-container {
    width: 99%;
  }
  .pond {
    width: 100%;
    height: 4rem;
    margin: 0 auto;
    /*opacity: 0;*/
    transition: opacity 1s .25s;
  }
  .upload-options {
    margin-bottom: 6px;
  }
  .prompt-section {
    .prompt-input {
      width: 99%;
    }
  }
}

@media only screen and (max-width: 370px) and (max-height: 567px) {
  .title {
    font-size: 25px;
  }
}

</style>
